<template>
  <div v-if="data">
    <div class="breadcrumb-text">
      Pelatihan / {{ data.name }} / Test / Pertanyaan
    </div>

    <div class="tab-container">
      <div v-ripple class="tab-item" v-on:click="$router.back()">
        &#60; Back
      </div>
    </div>

    <div class="q-action">
      <div class="q-action-btn" v-ripple @click="onSubmit">
        <img :src="`${baseAppPath}/media/save-green-icon.svg`" width="22px" />
        <div class="q-action-btn-text">Simpan</div>
      </div>

      <div class="divider"></div>

      <div class="q-action-btn" v-ripple>
        <img :src="`${baseAppPath}/media/reset-green-icon.svg`" width="22px" />
        <div class="q-action-btn-text">Reset</div>
      </div>
    </div>

    <div class="table-container">
      <div class="title">{{ data.name }}</div>
      <div class="time">Lama Pengerjaan: {{ data.time / 60 }} Menit</div>

      <b-form class="form" @submit.stop.prevent>
        <template v-for="(item, i) in questions.arr">
          <div :key="'q-' + i" v-if="item.id >= 0" class="q-item">
            <!-- <div class="q-item-index">Pertanyaan Nomor {{ i+1 }}</div> -->
            <div class="d-flex">
              <b-form-group class="flex-grow-1 mr-4">
                <b-form-input
                  v-model="item.question"
                  required
                  placeholder="Pertanyaan"
                  class="form-control form-control-solid h-auto py-3 pl-4 pr-7"
                  aria-describedby="input-1-live-feedback"
                ></b-form-input>

                <b-form-invalid-feedback id="input-1-live-feedback">
                  Pertanyaan is required.
                </b-form-invalid-feedback>
              </b-form-group>

              <b-form-group>
                <b-form-file
                  class="c-file-input form-control form-control-solid h-auto pl-3 pr-7 pb-0 mb-0"
                  v-model="item.img_location"
                ></b-form-file>
              </b-form-group>
            </div>

            <template v-for="(_item, answer_index) in item.arrData">
              <div
                v-if="_item.id >= 0"
                :key="'q-' + i + '-a-' + _item.id"
                class="q-answer-wrapper"
              >
                <div class="q-answer d-flex">
                  <div
                    @click="changeRightAnswer(item.arrData, answer_index)"
                    class="q-radio"
                  >
                    <div
                      :class="{ 'q-radio-active': _item.is_right == 1 }"
                    ></div>
                  </div>

                  <div class="q-answer-input flex-grow-1">
                    <b-form-group>
                      <b-form-input
                        v-model="_item.answer"
                        required
                        :placeholder="`Jawaban`"
                        class="form-control form-control-solid h-auto py-3 pl-4 pr-7"
                      ></b-form-input>

                      <b-form-invalid-feedback>
                        Jawaban is required.
                      </b-form-invalid-feedback>
                    </b-form-group>
                  </div>

                  <div
                    v-if="item.arrData.length > 1"
                    @click="deleteAnswer(item.arrData, answer_index)"
                    class="q-answer-delete"
                    v-ripple
                  >
                    <img
                      :src="`${baseAppPath}/media/cancel-icon.svg`"
                      width="14px"
                      height="14px"
                    />
                  </div>
                </div>
              </div>
            </template>

            <button
              @click="
                () => {
                  item.arrData = [
                    ...item.arrData,
                    { id: 0, answer: null, is_right: 0 }
                  ];
                  questions = { ...questions };
                }
              "
              class="btn btn-primary d-block font-weight-bolder px-6 py-3 mt-5 ml-11"
              style="border-radius: 10px"
              v-ripple
            >
              + Tambah Opsi
            </button>

            <v-divider></v-divider>

            <div class="d-flex align-items-center justify-content-between">
              <div>*Pilih salah satu opsi untuk menandakan jawaban benar</div>

              <div class="d-flex">
                <div
                  @click="duplicateQueston(item)"
                  class="q-answer-delete"
                  v-ripple
                >
                  <img
                    :src="`${baseAppPath}/media/copy-icon.svg`"
                    width="16px"
                  />
                </div>
                <div
                  v-if="questions.arr.length > 1"
                  @click="deleteQuestion(i)"
                  class="q-answer-delete"
                  v-ripple
                >
                  <img
                    :src="`${baseAppPath}/media//delete-2-icon.svg`"
                    width="16px"
                  />
                </div>
              </div>
            </div>
          </div>
        </template>
      </b-form>

      <div class="d-flex justify-content-end">
        <button
          @click="addQuestion"
          class="btn btn-secondary d-block font-weight-bolder px-6 py-3 mt-5 ml-11"
          style="border-radius: 10px"
          v-ripple
        >
          + Tambah Pertanyaan
        </button>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.form-group {
  margin-bottom: 10px;
}
.input-title {
  margin-bottom: 6px;
  color: #8c8c8c;
  font-size: 12px;
}
input {
  font-size: 14px !important;
  background-color: #ffffff !important;
  border: 1px solid #d7d4d3 !important;
  border-radius: 8px !important;
}

.c-file-input {
  .custom-file-input {
    height: inherit !important;
  }
  .custom-file-label {
    height: 40px;
    margin-bottom: 0px !important;
    border: 1px solid #d7d4d3 !important;
    border-radius: 8px !important;
  }
}
</style>

<style lang="scss" scoped>
.divider {
  height: 1px;
  background: rgba(31, 31, 31, 0.1);
}

.q-action {
  position: absolute;
  top: 44%;
  right: 0;
  border-radius: 10px 0px 0px 10px;
  background: #ffffff;
  z-index: 99;
  overflow: hidden;
  -webkit-box-shadow: 0px 0px 16px 0px rgba(6, 181, 111, 0.63);
  -moz-box-shadow: 0px 0px 16px 0px rgba(6, 181, 111, 0.63);
  box-shadow: 0px 0px 16px 0px rgba(6, 181, 111, 0.63);

  .q-action-btn {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
  }
  .q-action-btn-text {
    margin-top: 2px;
    font-size: 10px;
    text-align: center;
    color: #06b56f;
  }
}

.tab-container {
  display: flex;
  margin-bottom: 15px;

  .tab-item {
    padding: 5px 28px;
    margin-right: 10px;
    color: #06b56f;
    font-size: 12px;
    border: 1px solid #06b56f;
    border-radius: 8px;
    cursor: pointer;
    transition: all 400ms;
    -webkit-box-shadow: 7px 7px 0px 0px rgba(6, 181, 111, 0);
    -moz-box-shadow: 7px 7px 0px 0px rgba(6, 181, 111, 0);
    box-shadow: 7px 7px 0px 0px rgba(6, 181, 111, 0);

    &:hover {
      -webkit-box-shadow: 3px 3px 10px 0px rgba(6, 181, 111, 0.3);
      -moz-box-shadow: 3px 3px 10px 0px rgba(6, 181, 111, 0.3);
      box-shadow: 3px 3px 10px 0px rgba(6, 181, 111, 0.3);
    }
  }
  .tab-item-active {
    color: #ffffff;
    font-weight: bold;
    background: #06b56f;
    -webkit-box-shadow: 7px 7px 16px 0px rgba(6, 181, 111, 0.63);
    -moz-box-shadow: 7px 7px 16px 0px rgba(6, 181, 111, 0.63);
    box-shadow: 7px 7px 16px 0px rgba(6, 181, 111, 0.63);
  }
}

.title-wrapper {
  margin-bottom: 20px;
  padding: 0px 20px;
  border-left: 4px solid #0fe17a;
}
.title {
  margin-bottom: 6px;
  font-size: 18px;
  font-style: normal;
  font-weight: bold;
  color: #000000;
}
.time {
  margin-bottom: 6px;
  font-size: 14px;
  font-style: normal;
  color: #8c8c8c;
}
.desc {
  font-size: 16px;
  font-style: normal;
  font-weight: bold;
}
.modal-desc {
  font-size: 14px;
  font-style: normal;
}

.table-container {
  position: relative;
  min-height: 80vh;
  padding: 20px;
  margin-right: 40px;
  border-radius: 16px;
  background-color: #ffffff;
  -webkit-box-shadow: 14px 0px 22px 0px rgba(59, 116, 92, 0.04);
  -moz-box-shadow: 14px 0px 22px 0px rgba(59, 116, 92, 0.04);
  box-shadow: 14px 0px 22px 0px rgba(59, 116, 92, 0.04);
}

.q-item {
  margin-top: 25px;
  padding: 20px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  .q-item-index {
    margin-bottom: 8px;
  }

  .q-answer-wrapper {
    .q-radio {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 4px;
      margin-right: 10px;
      width: 26px;
      height: 26px;
      border-radius: 30px;
      border: 2px solid #06b56f;
      cursor: pointer;

      .q-radio-active {
        width: 16px;
        height: 16px;
        border-radius: 30px;
        background-color: #06b56f;
      }
    }
  }

  .q-answer-delete {
    margin-left: 10px;
    padding: 9px;
    height: 36px;
    border-radius: 4px;
    cursor: pointer;
  }
}

@media (max-width: 992px) {
}

@media (max-width: 768px) {
}

@media (max-width: 576px) {
}
</style>

<script>
import Moment from "moment";

import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

export default {
  name: "peningkatan_kapasitas_pertanyaan",
  props: ["id", "test_id"],
  components: {},
  data() {
    return {
      baseUrl: ApiService.baseUrl,
      routesName: ["Modul", "Video", "Tugas", "Test", "Report", "Peserta"],
      activeTabIndex: 0,

      data: null,
      mode: "create", // create, edit
      questions: {
        test_id: this.test_id,
        arr: [
          {
            id: 0,
            question: null,
            img_location: null,
            arrData: [
              {
                id: 0,
                answer: null,
                is_right: 1
              },
              {
                id: 0,
                answer: null,
                is_right: 0
              }
            ]
          }
        ]
      },

      dialog: false,
      confirmationDialog: false,
      dialogRole: false,
      confirmationDialogRole: false
    };
  },
  mounted() {
    this.getData();
  },
  beforeDestroy() {},
  methods: {
    async getData() {
      await ApiService.get(
        `api/testArray/${this.test_id}?token=${JwtService.getToken(
          "elearning"
        )}`,
        "elearning"
      )
        .then(({ data }) => {
          if (data?.data) {
            this.data = data?.data;

            let _questions = data.data.questions;

            for (let i = 0; i < _questions.length; i++) {
              _questions[i].arrData = _questions[i].choices;
            }

            if (data.data.questions.length > 0) {
              this.mode = "edit";
              this.questions.arr = _questions;
            }
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    changeRightAnswer(arrData, index) {
      arrData.forEach((item, idx) => {
        item.is_right = index === idx ? 1 : 0;
      });

      this.questions = {
        ...this.questions
      };
    },
    addQuestion() {
      this.questions.arr = [
        ...this.questions.arr,
        {
          id: 0,
          question: null,
          img_location: null,
          arrData: [
            {
              id: 0,
              answer: null,
              is_right: 1
            },
            {
              id: 0,
              answer: null,
              is_right: 0
            }
          ]
        }
      ];
    },

    deleteQuestion(index) {
      if (this.questions.arr[index].id == 0) {
        this.questions.arr.splice(index, 1);
      } else {
        this.questions.arr[index].id = -this.questions.arr[index].id;
      }
    },

    deleteAnswer(item, answer_index) {
      if (item[answer_index].id == 0) {
        item.splice(answer_index, 1);
      } else {
        item[answer_index].id = -item[answer_index].id;
      }

      this.questions = {
        ...this.questions
      };
    },

    duplicateQueston(item) {
      this.questions.arr = [...this.questions.arr, item];
    },

    onSubmit() {
      var _this = this;

      let formData = new FormData();

      formData.append("test_id", this.test_id);

      for (let i = 0; i < this.questions.arr.length; i++) {
        formData.append(`arr[${i}][question_id]`, this.questions.arr[i].id);
        formData.append(`arr[${i}][question]`, this.questions.arr[i].question);

        if (
          this.questions.arr[i].img_location &&
          typeof this.questions.arr[i].img_location != "string"
        ) {
          formData.append(
            `arr[${i}][img_location]`,
            typeof this.questions.arr[i].img_location != "string"
              ? this.questions.arr[i].img_location
              : null
          );
        }

        for (let j = 0; j < this.questions.arr[i].arrData.length; j++) {
          formData.append(
            `arr[${i}][arrData][${j}][answer_id]`,
            this.questions.arr[i].arrData[j].id
          );
          formData.append(
            `arr[${i}][arrData][${j}][answer]`,
            this.questions.arr[i].arrData[j].answer
          );
          formData.append(
            `arr[${i}][arrData][${j}][is_right]`,
            this.questions.arr[i].arrData[j].is_right
          );
        }
      }

      this.$swal({
        title: "Simpan Data?",
        showCancelButton: true,
        confirmButtonText: "Ya",
        cancelButtonText: "Tidak",
        showLoaderOnConfirm: true,
        preConfirm: function(result) {
          return new Promise(function(resolve, reject) {
            if (result) {
              ApiService.post(
                `api/testArray/${
                  _this.mode
                }?Content-Type=multipart/form-data&token=${JwtService.getToken(
                  "elearning"
                )}`,
                formData,
                "elearning"
              )
                .then(({ data }) => {
                  if (data?.success) {
                    resolve();
                  } else {
                    reject();
                  }
                })
                .catch(() => {
                  reject();
                })
                .finally(() => {
                  _this.loading = false;
                });

              // ApiService.delete(`api/pelatihan/${id}`, "elearning")
              //   .then(() => {
              //     _this.getData();
              //     resolve();
              //   })
              //   .catch(() => {
              //     reject();
              //   })
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading()
      });
    },

    formatDate(date) {
      return Moment(date).format("DD MMM YYYY [|] HH:mm [WIB]");
    },
    strippedContent(data) {
      let regex = /(<([^>]+)>)/gi;
      return data?.replace(regex, "");
    }
  }
};
</script>
